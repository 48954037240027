.complaintsContainer{
    display: grid;
    grid-template-columns: 70% 30%;
    grid-template-rows: auto;
    gap: 5px;
    margin: 0 0 15px 0;
}
.complaintsUser1{
    display: grid !important;
    grid-template-columns: 18% 82% !important;
    grid-template-rows: 1fr !important;
    gap: 10px !important;
    background: #fff;
    padding: 20px;
    border-radius: 8px;
}
.complaintsUser1Card div{
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
}
/*******************/
.complaintsUser2{
    display: grid;
    grid-template-columns: 70% 30%;
    grid-template-rows: auto;
    column-gap: 10px;
    background: #fff;
    padding: 20px;
    border-radius: 8px;
}
.complaintsUserBlock3{
    width: 100%;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
}
.complaintsUserBlock3 span{
    margin-bottom: 10px;
}
@media (min-width: 1048px) and (max-width: 1400px) {
    .complaintsUser2{
        display: flex;
        flex-direction: column;
        row-gap: 10px;
    }
}
@media (min-width: 1048px) and (max-width: 1400px) {}
@media (min-width: 320px) and (max-width: 1048px) {
    .complaintsContainer{
        display: flex;
        flex-direction: column;
    }
    .complaintsUser1{
        display: flex;
    }
    .complaintsUser2{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
    }
}